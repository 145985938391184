<template>
  <div
    class="sticky_button"
    style="overflow: hidden; padding: 5px 10px 10px 10px"
  >
    <div class="header">
      {{ $t("add_sticky_button.add_sticky_button") }}
    </div>
    <div v-if="!isLoading">
      <table>
        <tr>
          <th>{{ $t("admin.image") }}</th>
          <th>{{ $t("add_contact_info.url") }}</th>
          <th>{{ $t("admin.edit") }}</th>
        </tr>
        <tr>
          <td>
            <a :href="allStickyButton.url" target="_blank">
              <img
                style="height: 100px; width: 150px"
                :src="`${server_url}/${allStickyButton.image}`"
                alt=""
              />
            </a>
          </td>
          <td>
            <div class="short_text" v-html="allStickyButton.url"></div>
          </td>
          <td>
            <b-button
              @click="
                (modalShow = !modalShow),
                  editStickyButton(
                    allStickyButton._id,
                    allStickyButton.image,
                    allStickyButton.url
                  )
              "
            >
              <i class="fal fa-pen"></i>
            </b-button>
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="3"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
    <b-modal id="modal-xl-2" v-model="modalShow" size="xl" hide-footer>
      <div class="text_editor">
        <label for="">{{ $t("add_contact_info.url") }}</label>
        <b-form-input v-model="update.url"></b-form-input>
      </div>
      <div class="mt-2">
        <img
          style="height: 100px; width: 250px"
          :src="`${server_url}/${update.image}`"
        />
      </div>
      <input
        type="file"
        style="margin: 10px 0"
        accept="image/jpg, image/png, image/jpeg"
        @change="previewFiles($event)"
      />
      <button class="submit" @click="UpdateStickyButton()">
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  name: "getPartners",
  components: {
    spinner,
  },
  data() {
    return {
      modalShow: false,
      allStickyButton: [],
      active_id: "",
      update: {
        url: "",
        image: null,
      },
      server_url: env.server_url,
      isLoading: false,
    };
  },
  mounted() {
    this.getStickyButton();
  },
  methods: {
    getStickyButton() {
      this.isLoading = true;
      axios.get(`${env.host}/get/informations/sticky`).then((res) => {
        this.isLoading = false;
        console.log(res);
        this.allStickyButton = res.data.item;
      });
    },
    previewFiles(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.createBase64EmailIcon(image);
    },
    createBase64EmailIcon(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.update.image = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    editStickyButton(id, image, url) {
      this.active_id = id;
      this.update.image = image;
      this.update.url = url;
    },
    UpdateStickyButton() {
      this.isLoading = true;
      if (this.update.image.includes("uploads/image")) {
        this.update.image = null;
      }
      console.log(this.update);
      axios
        .post(`${env.host}/change/stickyInformation`, this.update)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getStickyButton();
          }, 1000);
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style scoped>
.team_management {
  width: 100%;
}
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}
button,
.header {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.short_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 15;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.header {
  color: white;
  width: 40%;
  margin-bottom: 10px;
}
.delete {
  background: #f02849;
  border-radius: 0px;
}
i {
  color: white;
}
table {
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}

td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
th,
td {
  padding: 10px;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0px;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
